import React from "react"

import {graphql} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "gatsby-image"
import styles from "./kontakt.module.scss"

export const query = graphql`
  query {
    file(relativePath: { eq: "sigve-bredde.jpg" }) {
      childImageSharp {
        fixed(width: 400) {
           ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const ContactPage = ({ data }) => (
  <Layout>
    <SEO title="Kontakt meg"/>
    <h1>Kontakt meg</h1>
        
    <Image className={styles.portrait}
           fixed={data.file.childImageSharp.fixed}
           alt="Portrett av Sigve Indregard" />
    
    <p>
      Dette nettstedet er eid og drevet av Sigve Indregard. Har du lyst å komme i
      kontakt med meg? Det kan ordnes. Bare fyll inn dette skjemaet:
    </p>

    <form className={styles.contact}
          method="post"
          action="https://getform.io/f/1bea6f3b-1798-43f4-9996-65bc91126bb1">
      <p>
        <label htmlFor="name">Navn</label>
        <input name="name" type="text"/>
      </p>
      <p>
        <label htmlFor="email">E-post</label>
        <input name="email" type="email"/>
      </p>
      <p>
        <label htmlFor="body">Hva lurer du på?</label>
        <textarea name="body" rows={8}/>
      </p>
      <input type="submit" value="Send inn!"/>
    </form>
    
    <p>
      I tillegg til å lage nettsteder som dette, har jeg vært journalist i Morgenbladet,
      elev- og lærlingombud i Oslo, medlem av den offentlige utredningen om fremtidens
      skole (<a href="https://www.regjeringen.no/no/dokumenter/nou-2015-8/id2417001/">
               «Ludvigsen-utvalget»
             </a>, 2013-2015) og redaktør for
      &nbsp;<cite>
              <a href="https://shop.flammeforlag.no/products/motgift">
                Motgift. Akademisk respons på den nye høyreekstremismen
              </a>
            </cite>&nbsp;
      (Flamme og Manifest forlag, 2012).
    </p>
    
    <p>
      Jeg er for tiden ledig for skriveoppdrag og foredrag.
    </p>
  </Layout>
)

export default ContactPage
